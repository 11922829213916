import { useEffect } from 'react';
import { TbSettings, TbChartBar, TbHelp } from 'react-icons/tb';

import Letter from 'src/elements/Letter';
import { useModal } from 'src/contexts/Modal';
import { SET_HELP_ON_LOAD, useSettings } from 'src/contexts/Settings';

import Help from '../Help';
import Setting from '../Setting';
import Stats from '../Stats';

import styles from './Nav.module.scss';

export function Nav() {
    const [settings] = useSettings();
    const setModal = useModal();

    useEffect(() => {
        if (!settings.helpOnLoad) {
            return;
        }

        setModal(<Help />);

        return () => {
            setModal(undefined);
        };
    }, []);

    function handleActivateModal(component) {
        return () => setModal(component);
    }

    return (
        <header className={styles.container}>
            <div className={styles.menuLeft}></div>
            <h1>Timely</h1>
            <div className={styles.menuRight}>
                <Letter title="Help" size='small' onClick={handleActivateModal(<Help />)}>
                    <TbHelp />
                </Letter>

                <Letter title="Stats" size='small' onClick={handleActivateModal(<Stats />)}>
                    <TbChartBar />
                </Letter>

                <Letter title="Settings" size='small' onClick={handleActivateModal(<Setting />)}>
                    <TbSettings />
                </Letter>
            </div>
        </header>
    );
}
