import classNames from 'classnames';

import styles from './Timer.module.scss';

export function Timer({ className, value }) {
    return (
        <div className={classNames(styles.container, className)}>
            {value === Infinity ? '∞' : value}
        </div>
    );
}
