import { createContext, useContext, useReducer } from "react";

import { useStats } from "./contexts/Stats";
import { useTranslation } from "./contexts/Translation";

import AppReducer, { initialState } from "./App.reducer";

const AppContext = createContext();

export function AppContextProvider({ maxRounds, children }) {
    const t = useTranslation();
    const [stats] = useStats();

    const [app, appDispatch] = useReducer(AppReducer, initialState(t.words, maxRounds, stats.lastPlayed));

    return (
        <AppContext.Provider value={[app, appDispatch]}>
            {children}
        </AppContext.Provider>
    );
}

export function useApp() {
    const context = useContext(AppContext);

    if (!context) {
        throw new Error('useApp must be used within AppContextProvider');
    }

    return context;
}
