import classNames from 'classnames';
import { addDays, hoursToSeconds, intervalToDuration, minutesToSeconds, startOfDay } from 'date-fns';
import { TbShare, TbTrophy } from 'react-icons/tb';

import { useApp } from 'src/App.context';

import useTimer from 'src/hooks/useTimer';
import { useStats } from "src/contexts/Stats";
import { useTranslation } from "src/contexts/Translation";
import { useToast } from 'src/contexts/Toast';
import { useModal } from 'src/contexts/Modal';

import styles from './Stats.module.scss';

const timeIntl = Intl.NumberFormat('en', {
    minimumIntegerDigits: 2,
});

const percIntl = Intl.NumberFormat('en', {
    maximumFractionDigits: 0,
});

export function Stats() {
    const [app] = useApp();
    const [stats] = useStats();
    const setToastr = useToast();
    const setModal = useModal();

    const t = useTranslation();
    const { time, stopTimer } = useTimer();

    function handleScoreClick() {
        setModal(undefined);
    }

    async function handleShareClick() {
        const firstLoss = app.boardState.indexOf(undefined);

        const results = app.boardState
            .filter((state) => state)
            .map((state, index) => {
                const round = app.roundState[index];
                const startIndex = state.word.indexOf(round.hint);

                const emoji = state.word.split('').map((letter, idx) => {
                    if (idx >= startIndex && idx < startIndex + round.hint.length) {
                        return `⬜`;
                    }
                    return `🟩`;
                }).join('');

                return `${emoji} ${state.timeSpent} ${t.stats.share.secs}`;
            });

        let copy;

        if (firstLoss === -1) {
            copy = results.join(`\n`);
        } else {
            copy = [...results, `🟥🟥🟥🟥🟥 15 ${t.stats.share.secs}`].join('\n');
        }

        await navigator.clipboard.writeText(copy);
        setToastr("Results copied to clipboard");
    }

    const nextGameInterval = intervalToDuration({
        end: startOfDay(addDays(new Date(), 1)),
        start: new Date(),
    });

    const hoursInSeconds = hoursToSeconds(nextGameInterval.hours);
    const minutesInSeconds = minutesToSeconds(nextGameInterval.minutes);
    const timerLimit = hoursInSeconds + minutesInSeconds + nextGameInterval.seconds;

    if (time > timerLimit) {
        stopTimer();
    }

    return (
        <div className={styles.container}>
            <div className={styles.stats}>
                <h2>{t.stats.title}</h2>
                <div>
                    <h3>{stats.gamesPlayed}</h3>
                    <p>{t.stats.played}</p>
                </div>
                <div>
                    <h3>{!stats.gamesPlayed ? 0 : percIntl.format(stats.attempts[5] * 100 / stats.gamesPlayed)}%</h3>
                    <p>{t.stats.winPercentage}</p>
                </div>
                <div>
                    <h3>{stats.streak}</h3>
                    <p>{t.stats.currentStreak}</p>
                </div>
                <div>
                    <h3>{stats.maxStreak}</h3>
                    <p>{t.stats.maxStreak}</p>
                </div>
            </div>

            <div className={styles.roundDist}>
                <h2>{t.stats.roundDistribution}</h2>
                {
                    Object.entries(stats.attempts).map(([key, value],) => {
                        return (
                            <div
                                key={key}
                                className={classNames({ 'win': key === '5' })}
                                style={{ width: `${!stats.gamesPlayed ? 0 : value * 100 / stats.gamesPlayed}%` }}
                            >
                                <p>{Number(key) + 1}</p>
                                <div className={classNames({
                                    [styles.current]: app.status !== 'pending'
                                        ? (Number(key) === app.boardState.indexOf(undefined) - 1 || (app.boardState.indexOf(undefined) === -1 && Number(key) === 5))
                                        : false
                                })}><p>{value}</p></div>
                            </div>
                        );
                    })
                }
            </div>

            {
                app.status !== 'pending'
                    ? (
                        <div className={styles.action}>
                            <p>{t.stats.nextGameIn} {timeIntl.format(nextGameInterval.hours)}:{timeIntl.format(nextGameInterval.minutes)}:{timeIntl.format(nextGameInterval.seconds)}</p>
                            <div>
                                <button onClick={handleScoreClick}>{t.stats.showScore}</button>
                                <button onClick={handleShareClick}>{t.stats.share.label} <TbShare /></button>
                            </div>
                        </div>
                    ) : null
            }
        </div>
    );
}
