import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.scss';
import reportWebVitals from './reportWebVitals';

import ModalContextProvider from './contexts/Modal';
import StatsContextProvider from './contexts/Stats';
import SettingsContextProvider from './contexts/Settings';
import ToastContextProvider from './contexts/Toast';
import TranslationContextProvider from './contexts/Translation';

import App from './App';
import { AppContextProvider } from './App.context';

const maxRounds = 6;
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <TranslationContextProvider>
      <SettingsContextProvider>
        <StatsContextProvider maxRounds={maxRounds}>
          <AppContextProvider maxRounds={maxRounds}>
            <ToastContextProvider>
              <ModalContextProvider>
                <App maxRounds={maxRounds} />
              </ModalContextProvider>
            </ToastContextProvider>
          </AppContextProvider>
        </StatsContextProvider>
      </SettingsContextProvider>
    </TranslationContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
