import classNames from "classnames";
import { useTranslation } from "src/contexts/Translation";
import Letter from "src/elements/Letter";

import styles from './Hint.module.scss';

export function Hint({ className, value, size = "large" }) {
    const t = useTranslation();

    return (
        <div className={classNames(styles.container, className)}>
            <div className={styles.letters}>
                {value.split('').map((letter, index) => <Letter key={index} size={size}>{letter}</Letter>)}
            </div>
        </div>
    );
}
