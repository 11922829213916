import assert from "assert";
import SeedRandom from "seedrandom";
import { getDayOfYear, isToday } from "date-fns";

export const SET_STATUS = Symbol('SET_STATUS');
export const GOTO_NEXT_ROUND = Symbol('GOTO_NEXT_ROUND');

function generateHint(length, words, maxRounds, idx) {
    const date = new Date();
    const day = getDayOfYear(date);

    const startIndex = day * maxRounds;
    const maxRoundsWords = words.slice(startIndex, startIndex + maxRounds);
    const word = maxRoundsWords[idx];

    const wordLength = word.length;
    const seed = SeedRandom(`${day}_${idx}`);
    const randomLetterIndex = Math.floor(seed() * wordLength);

    const start = randomLetterIndex + length > wordLength ? randomLetterIndex - length : randomLetterIndex;
    const letterList = [...new Array(length)].map((_, index) => word[index + start]);

    return letterList.join('');
};

function generateRounds(words, maxRounds) {
    return [...new Array(maxRounds)].map((_, idx) => {
        if (idx === 0) {
            return { timeLimit: Infinity, hint: generateHint(2, words, maxRounds, idx) };
        }

        return { timeLimit: 15, hint: generateHint(3, words, maxRounds, idx) };
    });
}

export const initialState = (words, maxRounds, lastPlayed) => {
    try {
        const localGame = JSON.parse(localStorage.getItem('timely-game') || "null");

        if (!lastPlayed || !isToday(lastPlayed)) {
            return {
                status: 'pending',
                roundState: generateRounds(words, maxRounds),
                boardState: [...new Array(maxRounds)],
            };
        }

        assert(localGame);
        assert(localGame.status);
        assert(localGame.roundState);
        assert(localGame.boardState);

        return {
            status: localGame.status,
            roundState: localGame.roundState,
            boardState: localGame.boardState.map((a) => !a ? undefined : a),
        };
    }
    catch (err) {
        return {
            status: 'pending',
            roundState: generateRounds(words, maxRounds),
            boardState: [...new Array(maxRounds)],
        };
    }
};

export default (state, action) => {
    let game;

    switch (action.type) {
        case GOTO_NEXT_ROUND:
            if (state.status !== 'pending') {
                return state;
            }

            game = {
                ...state,
                boardState: [
                    ...state.boardState.slice(0, action.payload.roundIndex),
                    {
                        word: action.payload.guess,
                        timeSpent: action.payload.timer,
                    },
                    ...state.boardState.slice(action.payload.roundIndex + 1),
                ],
                lastPlayed: state.roundIndex === 0 ? new Date() : state.lastPlayed,
            };
            break;

        case SET_STATUS:
            game = {
                ...state,
                status: action.payload,
            };
            break;

        default:
            game = state;
    }

    localStorage.setItem('timely-game', JSON.stringify(game));

    return game;
};
