import { useEffect, useState } from 'react';
import { useAnimationControls } from 'framer-motion';

import Guess from 'src/components/Guess';
import Hint from 'src/components/Hint';

import Timer from 'src/elements/Timer';
import useTimer from 'src/hooks/useTimer';
import { useTranslation } from 'src/contexts/Translation';

import styles from './Game.module.scss';

export function Game({ hint, timeLimit, onFail, onSubmit }) {
    const t = useTranslation();
    const control = useAnimationControls();
    const winControl = useAnimationControls();

    const { time, stopTimer } = useTimer();

    const [error, setError] = useState();
    const [guess, setGuess] = useState('');

    useEffect(() => {
        if (!error) {
            return;
        }

        control.start({
            x: [20, 0],
            transition: { type: 'spring', bounce: 0.9, ease: "easeIn" },
        }).then(() => {
            setGuess('');
            setError(false);
            control.stop();
        });

        return () => {
            control.stop();
        };
    }, [error]);

    useEffect(() => {
        if (time <= timeLimit) {
            return;
        }

        onFail();
        stopTimer();

        return () => {
            stopTimer();
        };
    }, [time]);

    useEffect(() => {
        if (guess.length !== 5) {
            return;
        }

        handleSubmit();
    }, [guess]);

    function handleSubmit() {
        if (!guess.includes(hint)) {
            setError('guess is not in hint');
            return;
        }

        if (!t.words.includes(guess)) {
            setError('guess is not in word list');
            return;
        }

        stopTimer();

        winControl.start({
            y: [0, -25, 0, -5, 0],
        }).then(() => {
            onSubmit(guess, time);
            winControl.stop();
        });
    }

    return (
        <div className={styles.container}>
            <Hint value={hint} />

            <Timer
                value={timeLimit - time}
            />

            <Guess
                value={guess}
                control={control}
                winControl={winControl}
                onChange={setGuess}
            />
        </div>
    );
}
