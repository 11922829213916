import classNames from "classnames";
import { useRef } from "react";
import { useTranslation } from "src/contexts/Translation";
import Letter from "src/elements/Letter";

import styles from './Score.module.scss';

export function Score({ maxRounds }) {
    const t = useTranslation();
    const ref = useRef(JSON.parse(localStorage.getItem('timely-game') || "null"));

    if (!ref.current) {
        return;
    }

    return (
        <div className={styles.container}>
            {
                [...new Array(maxRounds)].map((_, idx) => {
                    if (!ref.current) {
                        return;
                    }

                    const round = ref.current.roundState[idx];
                    const board = ref.current.boardState[idx];

                    return (
                        <div key={`round_${idx}`} className={styles.round}>
                            <h3>Round {idx + 1}</h3>
                            <div className={styles.guess}>
                                {
                                    !board
                                        ? t.words.filter(w => w.includes(round.hint)).slice(0, 3).map((w, i) => {
                                            const startIndex = w.indexOf(round.hint);
                                            return (
                                                <div key={`${w}_${i}`} className={styles.possibleWord}>
                                                    {
                                                        w.split('').map((letter, idx) => {
                                                            return (
                                                                <Letter
                                                                    key={idx}
                                                                    className={classNames({ [styles.hintLetter]: idx >= startIndex && idx < startIndex + round.hint.length })}
                                                                    size="small"
                                                                >
                                                                    {letter}
                                                                </Letter>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            );
                                        })
                                        : <div className={styles.word}>
                                            {
                                                board.word.split('').map((letter, idx) => {
                                                    const startIndex = board.word.indexOf(round.hint);
                                                    return (
                                                        <Letter
                                                            key={idx}
                                                            className={classNames({ [styles.hintLetter]: idx >= startIndex && idx < startIndex + round.hint.length })}
                                                            size="small"
                                                        >
                                                            {letter}
                                                        </Letter>
                                                    );
                                                }
                                                )
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}
