import { useRef, useState } from "react";

export default function useTimer() {
    const timerRef = useRef();
    const [time, setTime] = useState(0);

    if (!timerRef.current) {
        startTimer();
    }

    function startTimer() {
        timerRef.current = setInterval(() => {
            setTime(time => time + 1);
        }, 1000);
    }

    function stopTimer() {
        clearInterval(timerRef.current);
    }

    return {
        time,
        startTimer,
        stopTimer,
    };
}
