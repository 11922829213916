import { useMemo, useState } from "react";

import { useModal } from "./contexts/Modal";
import { SET_GAME_STATUS, useStats } from "./contexts/Stats";
import { useToast } from "./contexts/Toast";

import Game from "./layouts/Game";
import Nav from "./layouts/Nav";
import Score from "./layouts/Score";
import Stats from "./layouts/Stats";

import { GOTO_NEXT_ROUND, SET_STATUS } from "./App.reducer";
import { useApp } from "./App.context";

function App({ maxRounds }) {
  const setModal = useModal();
  const setToastr = useToast();

  const [_, dispatchStats] = useStats();
  const [app, appDispatch] = useApp();

  const [roundIndex, setRoundIndex] = useState(0);

  const round = useMemo(() => app.roundState[roundIndex], [roundIndex]);

  function handleFail() {
    setToastr("Better luck next time!");
    setModal(<Stats />);
    appDispatch({ type: SET_STATUS, payload: 'failed' });
    dispatchStats({ type: SET_GAME_STATUS, payload: { status: 'failed', roundIndex } });
  }

  function handleSubmit(guess, timer) {
    appDispatch({ type: GOTO_NEXT_ROUND, payload: { guess, timer, roundIndex } });

    if (roundIndex + 1 !== maxRounds) {
      setRoundIndex(roundIndex + 1);
      return;
    }

    setToastr("Well done!");
    setModal(<Stats />);
    appDispatch({ type: SET_STATUS, payload: 'success' });
    dispatchStats({ type: SET_GAME_STATUS, payload: { status: 'success', roundIndex } });
  }

  return (
    <>
      <Nav />
      <main>
        {
          app.status === 'pending'
            ? <Game
              key={`round_${roundIndex}`}
              hint={round.hint}
              timeLimit={round.timeLimit}
              onFail={handleFail}
              onSubmit={handleSubmit}
            /> : null
        }

        {
          app.status === 'success' || app.status === 'failed'
            ? <Score maxRounds={maxRounds} /> : null
        }

        {
          app.status !== 'pending' && app.status !== 'failed' && app.status !== 'success'
            ? <h1>Issue with app</h1> : null
        }
      </main>
    </>
  );
}

export default App;
