import { TbArrowRight } from "react-icons/tb";
import Hint from "src/components/Hint";
import { useModal } from "src/contexts/Modal";
import { SET_HELP_ON_LOAD, useSettings } from "src/contexts/Settings";
import { useTranslation } from "src/contexts/Translation";
import Letter from "src/elements/Letter";

import styles from './Help.module.scss';

export function Help() {
    const t = useTranslation();
    const setModal = useModal();
    const [settings, settingsDispatch] = useSettings();

    function handleClose() {
        setModal(undefined);
    }

    function handlePlayGame() {
        handleClose();
        settingsDispatch({ type: SET_HELP_ON_LOAD, payload: false });
    }

    return (
        <div className={styles.container}>
            <h2>{t.help.title}</h2>
            <h5>{t.help.subTitle}</h5>

            <ul>
                <li>{t.help.rule1}</li>
                <li>{t.help.rule2}</li>
                <li>{t.help.rule3}</li>
            </ul>

            <h3>{t.help.example}</h3>
            <Hint value="pin" size="small" />
            <p dangerouslySetInnerHTML={{ __html: t.help.exampleDescriptionHtml }} />

            {
                settings.helpOnLoad
                    ? <button onClick={handlePlayGame}>{t.help.play}</button>
                    : <button onClick={handleClose}>{t.close}</button>
            }
        </div>
    );
}
