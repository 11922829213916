import Toggle from "src/elements/Toggle";

import { SET_DARK_MODE, useSettings } from "src/contexts/Settings";
import { useTranslation } from "src/contexts/Translation";

import styles from './Setting.module.scss';

export function Setting() {
    const t = useTranslation();
    const [settings, settingsDispatch] = useSettings();

    function handleDarkModeToggle() {
        settingsDispatch({ type: SET_DARK_MODE, payload: !settings.darkMode });
    }

    return (
        <div className={styles.container}>
            <h2>{t.settings.title}</h2>
            <div>
                <label>Dark Mode</label>
                <Toggle value={settings.darkMode} onChange={handleDarkModeToggle} />
            </div>
            <div>
                <label>{t.settings.feedback.label}</label>
                <div dangerouslySetInnerHTML={{ __html: t.settings.feedback.valueHtml }} />
            </div>
        </div>
    );
}
